<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-row align="start" class="ml-2">
            <v-card-title>{{ $t("general.comments") }}</v-card-title>
            <v-col class="d-flex justify-end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="Lb_DialogAddNewComment = true"
                    color="success"
                    fab
                    small
                    dark
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-comment-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("general.new_comment") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row align="start" class="ml-2">
            <base-hover-button
              class="ml-4"
              color="primary lighten-5"
              text-color="primary--text"
              :text="$t('general.back')"
              @click.native="goBack()"
              iconName=" mdi-arrow-left-circle"
            />
            <v-spacer />
            <v-col class="d-flex" cols="11" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.search')"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="La_Comments"
            item-key="i_SSE_DatasetCommentID"
            class="elevation-1 table-one"
            multi-sort
            :loading="Lb_Isloded"
            :loading-text="$t('general.please_wait')"
            :footer-props="{
              'items-per-page-text': $t('general.questions_per_page'),
            }"
          >
            <template v-slot:item.s_Author="{ item }">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Author }}
                </p>
              </div>
            </template>
            <template v-slot:item.t_Date="{ item }">
              {{ formatDate(item.t_Date) }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="showComment(item)"
                  >
                    <v-icon>mdi-comment</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.s_Comment }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
    <!--add-new-comment-->
    <v-dialog v-model="Lb_DialogAddNewComment" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("general.new_comment") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  :label="$t('general.comment')"
                  required
                  v-model="Ls_Comment"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :value="Ls_CurrentDateformat"
                  :label="$t('general.date')"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="Lb_DialogAddNewComment = false"
          >
            {{ $t("general.close") }}
          </v-btn>
          <v-btn
            :loading="Lb_Saving"
            :disabled="(Lb_Saving, NoComment)"
            color="green darken-1"
            text
            @click="addNewComment()"
          >
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end--add-new-comment-->
    <!--view-comment-->
    <v-dialog v-model="Lb_DialogViewComment" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("general.comments") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  rows="1"
                  readonly
                  required
                  v-model="Lo_ViewComment.s_Comment"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :value="Lo_ViewComment.t_Date"
                  :label="$t('general.date')"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="Lb_DialogViewComment = false"
          >
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end--view-comment-->
  </v-row>
</template>

<script>
import IconClassBar from "../../../components/base/IconClassBar.vue";
import { post, get } from "../../../worker/worker-api";
import { mapGetters } from "vuex";
export default {
  name: "QuestionComment",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.question_comments"),
    };
  },
  components: {
    IconClassBar,
  },
  props: {
    i_TestID: {
      type: Number,
    },
    i_TestTypeID: {
      type: Number,
    },
  },
  methods: {
    goBack() {
      if (this.i_TestTypeID == 1) {
        this.$router.push({ name: "ci-oral-questions" });
      } else if (this.i_TestTypeID == 2) {
        this.$router.push({ name: "fg-oral-questions" });
      } else {
        this.$router.back();
      }
    },
    getTestCommentsList() {
      this.Lb_Isloded = true;
      get(`${process.env.VUE_APP_SMT_API_URL}/teacher/question-comments-list`, {
        i_TestID: this.i_TestID,
      })
        .then((response) => {
          if (response.status === 401) {
            this.signOut();
            this.$smt.logout();
            return;
          }
          this.La_Comments = response.a_TestQuestionComments;
          this.Lb_Isloded = false;
        })
        .catch((error) => {
          console.log("Worker error: ", error);
        });
    },
    showComment(Ao_Comment) {
      this.Lb_DialogViewComment = true;
      this.Lo_ViewComment.s_Author = Ao_Comment.s_Author;
      this.Lo_ViewComment.t_Date = this.formatDate(Ao_Comment.t_Date);
      this.Lo_ViewComment.s_Comment = Ao_Comment.s_Comment;
    },
    addNewComment() {
      this.Lb_Saving = true;
      post(`${process.env.VUE_APP_SMT_API_URL}/teacher/set-question-comment`, {
        o_QuestionComment: {
          i_TestID: this.i_TestID,
          s_Author: this.getProfile.firstname + " " + this.getProfile.lastname,
          s_Comment: this.Ls_Comment,
        },
      })
        .then((response) => {
          this.getTestCommentsList();
          this.Lb_Saving = false;
          this.Lb_DialogAddNewComment = false;
          this.Ls_Comment = "";
        })
        .catch((errors) => {
          console.log("submit Worker error: ", errors);
        });
    },
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        let month = (d.getMonth() + 1).toString().padStart(2, "0");
        let day = d.getDate().toString().padStart(2, "0");
        let year = d.getFullYear();
        let Hour = d.getHours();
        if(Hour == 0){
          Hour = "00";
        }
        else if(Hour < 10)
        {
          Hour = "0" + Hour;
        }
        let Minute = d.getMinutes();
        if(Minute == 0){
          Minute = "00";
        }
        else if(Minute < 10)
        {
          Minute = "0" + Minute;
        }
        let Second = d.getSeconds();
        if(Second == 0){
          Second = "00";
        }
        else if(Second < 10)
        {
          Second = "0" + Second;
        }
        let L_Date = [day, month, year].join("-");
        let Time = [Hour, Minute, Second].join(":");
        return [L_Date,Time].join("-");
      } // if
      else {
        return "";
      } // else
    },
  },
  computed: {
    NoComment() {
      if (this.Ls_Comment === "") return true;
    },
    Ls_CurrentDateformat() {
      return (this.formatDate(this.Lt_CurrentDate));
    },
    ...mapGetters(["getProfile"]),
  },
  created() {
    if (this.i_TestID) {
      this.getTestCommentsList();
    } else {
      //go to the last page
      this.$router.back();
    }
  },
  data() {
    return {
      Lb_DialogViewComment: false,
      Lt_CurrentDate: new Date(),
      Lb_DialogAddNewComment: false,
      Ls_Comment: "",
      Lb_Saving: false,
      La_Comments: [],
      Lb_Isloded: false,
      search: "",
      selected: [],
      headers: [
        {
          text: this.$t("general.author"),
          align: "start",
          sortable: false,
          value: "s_Author",
        },
        { text: this.$t("general.date"), value: "t_Date" },
        { text: this.$t("general.comment"), value: "action" },
      ],
      Lo_ViewComment: {
        s_Author: "",
        t_Date: "",
        s_Comment: "",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
